<template>
  <div>
    <CCard>
      <CCardHeader>
        {{ title }}
      </CCardHeader>
      <CCardBody>
        <AbstractForm
          :formContent="formContent"
          @emit-methods="onEmitMethods"
          :fields="typeCategoriesFields"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { createNewTypeCategory, getCategoriesTypeData } from "@/services/category_type";
import AbstractForm from "@/components/Forms/AbstractForm.vue";

export default {
  name: "addTypeCategories",
  components: { AbstractForm },
  data() {
    return {
      title: "Añadir un nuevo tipo de categoría",
      id: null,
      categoriesType: [],
      typeCategoriesFields: {
        name: null
      },
      formContent: {
        buttons: [
          {
            textButton: "Volver",
            color: "secondary",
            event: "back",
            style: "margin: 10px",
            active: true,
          },
          {
            textButton: "Crear",
            color: "success",
            event: "add",
            style: "margin: 10px",
            active: this.$store.state.isAdmin,
          },
        ],
        rows: [
          {
            style: "",
            class: "",
            content: [
              {
                type: "element",
                rule: [],
                style: "",
                class: "",
                content: {
                  type: "input",
                  value: "",
                  key: "name",
                  label: "Nombre",
                  maxLength: 30,
                  specialCharactersAllowed: false,
                  allowSpaces: true,
                  error: ''
                },
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    this.getCategoriesType();
  },
  methods: {
    onEmitMethods(event, dataTypeCategory) {
      switch (event) {
        case "add":this.addTypeCategory(dataTypeCategory);break;
        case "back":this.back();break;
        default:break;
      }
    },
    showAlertError(msg) {
      this.$store.state.errorAlert = { status: true, msg: msg.toString() };
    },
    /**
     * Función en la que se obtienen los tipos de categoría
     *
     * @returns {object[]} Los tipos de categoría obtenidos.
     */
     getCategoriesType() {
       getCategoriesTypeData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString(),};
        })
        .then((result) => {
          this.categoriesType = result;
        });
    },
    /**
     * Método para validar los inputs del formulario
     */
     checkInputs(dataTypeCategory) {
            const specialCharactersRegex = /^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s]+$/;
            const validationRules = [
                { name: 'Nombre', field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
                { name: 'Nombre', field: 'name', message: 'El nombre no debe contener caracteres especiales.', regex: specialCharactersRegex }
            ];

            const existingCategoryType = this.categoriesType.find(categoryType => {
                return categoryType.name.toLowerCase() === dataTypeCategory.name.toLowerCase()
            });
            
            if (existingCategoryType) {
                this.showAlertError(`El nombre "${dataTypeCategory.name}" ya existe en los tipos de categoría.`);
                return false;
            }

            for (const rule of validationRules) {
                const value = dataTypeCategory[rule.field];
                if (value === undefined || value === null) {
                    this.showAlertError(`El campo ${rule.name} está vacío`);
                    return false;
                }
                if(rule.maxLength && value.length > rule.maxLength){
                    this.showAlertError(`El número de caracteres de ${rule.name} debe ser menor o igual al siguiente valor: ${rule.maxLength}`);
                    return false;
                }
                if (!value || (rule.maxLength && value.length > rule.maxLength) || (rule.minLength && value.length < rule.minLength) || (rule.noSpaces && value.includes(' ')) || (rule.value && value === rule.value) || (rule.minValue && parseInt(value) < rule.minValue) || (rule.equalField && value !== dataTypeCategory[rule.equalField]) || (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
    /**
     * Método para poder crear una nueva categoría
     */
     addTypeCategory(dataTypeCategory) {
      if (this.checkInputs(dataTypeCategory) == false) return;
      createNewTypeCategory(dataTypeCategory)
        .then(() => {
          this.$router.push("/type-categories");
        })
        .catch((error) => {
          this.$store.state.errorAlert = {status: true, msg: error.toString()};
        });
    },
    /**
     * Función para mostrar el alert durante 10 segundos.
     */
    showFailureParameters() {
      this.contAlertParams = 10;
    },
    /**
     * Función para volver atrás
     */
    back() {
      this.$router.push("/type-categories");
    },
  },
};
</script>